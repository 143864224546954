import React from 'react'
import * as Scroll from 'react-scroll';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


function Header() {
    return (
        <header id="header" className="fixed-top">
    <div className="container d-flex align-items-center">

      {/* <h1 className="logo me-auto"><a href="index.html">Skysil</a></h1> */}
      {/* <!-- Uncomment below if you prefer to use an image logo --> */}
      {/* </header> */}
      <a href="index.html" className="logo me-auto"><img src="assets/img/logo.png" alt="" className="img-fluid"/></a>

      <nav id="navbar" className="navbar order-last order-lg-0">
        <ul>
          {/* <li><a className="active" href="index.html">Home</a></li> */}
          <Link activeClass="active" to="hero" spy={true} smooth={true} offset={-70} duration={250}>Home</Link>
          <Link activeClass="active" to="about" spy={true} smooth={true} offset={-70} duration={250}>About</Link>
          <Link activeClass="active" to="why-us" spy={true} smooth={true} offset={-70} duration={250}>Guidelines</Link>
          <Link activeClass="active" to="speakers" spy={true} smooth={true} offset={-70} duration={250}>Speakers</Link>
          {/* <Link activeClass="active" to="about" spy={true} smooth={true} offset={-70} duration={500}>About</Link> */}

          {/* <li><a href="about.html">About</a></li> */}
          {/* <li><a href="courses.html">Speakers</a></li>
          <li><a href="trainers.html">Trainers</a></li>
          <li><a href="events.html">Events</a></li>
          <li><a href="pricing.html">Pricing</a></li> */}

          {/* <li className="dropdown"><a href=""><span>Drop Down</span> <i className="bi bi-chevron-down"></i></a>
            <ul>
              <li><a href="">Drop Down 1</a></li>
              <li className="dropdown"><a href=""><span>Deep Drop Down</span> <i className="bi bi-chevron-right"></i></a>
                <ul>
                  <li><a href="">Deep Drop Down 1</a></li>
                  <li><a href="">Deep Drop Down 2</a></li>
                  <li><a href="">Deep Drop Down 3</a></li>
                  <li><a href="">Deep Drop Down 4</a></li>
                  <li><a href="">Deep Drop Down 5</a></li>
                </ul>
              </li>
              <li><a href="">Drop Down 2</a></li>
              <li><a href="">Drop Down 3</a></li>
              <li><a href="">Drop Down 4</a></li>
            </ul>
          </li>
          <li><a href="contact.html">Contact</a></li> */}
        </ul>
        {/* <i className="bi bi-list mobile-nav-toggle"></i> */}
      </nav>

      <a href="get-started.html" className="get-started-btn">Get Started</a>

    </div>
  </header>
    )
}

export default Header
