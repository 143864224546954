import React from 'react'

function BannerSection() {
    return (
    <section id="hero" className="d-flex justify-content-center align-items-center">
        <div className="container position-relative" data-aos="zoom-in" data-aos-delay="100">
            <h1>Making<br/>Opportunities<br/>Accessible</h1>
            {/* <h2></h2> */}
            <a href="get-started.html" className="btn-get-started">Get Started</a>
        </div>
    </section>
    )
}

export default BannerSection
