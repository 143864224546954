import Header from "./components/header";
import BannerSection from "./components/banner-section";
import Main from "./components/main";
import Footer from "./components/footer";
import React from "react";

function App() {
  return (
    <>
  <Header />
  <BannerSection />
  <Main />
  <Footer />
  
  {/*<div id="preloader"></div>
  <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
  */}
  </>
  );
}

export default App;
