import React from 'react'
import * as Scroll from 'react-scroll';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

function Main() {
  return (
    <main id="main">

      <section id="about" className="about">
        <div className="container" data-aos="fade-up">

          <div className="row">
            <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
              <img src="assets/img/Intro pic.png" className="img-fluid" alt="" />
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
              <h3>About</h3>
              <p className="fst-italic">
                Our mission is to inspire the next generation of dreamers to grow, by enabling a knowledge network of students and experienced professionals interacting via focused speaker sessions.
              </p>
              <ul>
                <li><i className="bi bi-check-circle"></i> For students seeking career guidance</li>
                <li><i className="bi bi-check-circle"></i> For experienced professionals aiming to improve their public speaking skills </li>
                <li><i className="bi bi-check-circle"></i> For communities looking to easily book sessions with speakers and industry professionals</li>
              </ul>
              <p>
                Join the community to connect with speakers and professionals or do your part in spreading knowledge to budding professionals.
              </p>

            </div>
          </div>

        </div>
      </section>

{/* 
      <section id="counts" className="counts section-bg">
        <div className="container">

          <div className="row counters">

            <div className="col-lg-3 col-6 text-center">
              <span data-purecounter-start="0" data-purecounter-end="1232" data-purecounter-duration="1" className="purecounter"></span>
              <p>Students</p>
            </div>

            <div className="col-lg-3 col-6 text-center">
              <span data-purecounter-start="0" data-purecounter-end="64" data-purecounter-duration="1" className="purecounter"></span>
              <p>Courses</p>
            </div>

            <div className="col-lg-3 col-6 text-center">
              <span data-purecounter-start="0" data-purecounter-end="42" data-purecounter-duration="1" className="purecounter"></span>
              <p>Events</p>
            </div>

            <div className="col-lg-3 col-6 text-center">
              <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1" className="purecounter"></span>
              <p>Trainers</p>
            </div>

          </div>

        </div>
      </section> */}


      <section id="why-us" className="why-us">
        <div className="container" data-aos="fade-up">
        <div className="section-title">
            <h2>Guidelines</h2>
            {/* <p>Upcoming Speakers</p> */}
          </div>
          <div className="row">
            <div className="col-lg-4 d-flex align-items-stretch">
              <div className="content">
                <h3>Guidelines</h3>
                <p>
                  Coming Soon!
                </p>
                <div className="text-center">
                <Link activeClass="active" className="more-btn" to="about" spy={true} smooth={true} offset={-70} duration={250}>Learn More<i className="bx bx-chevron-right"></i></Link>

                  {/* <a href="about.html" className="more-btn">Learn More <i className="bx bx-chevron-right"></i></a> */}
                </div>
              </div>
            </div>
            <div className="col-lg-8 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              {/* <div className="icon-boxes d-flex flex-column justify-content-center">
                <div className="row">
                  <div className="col-xl-4 d-flex align-items-stretch">
                    <div className="icon-box mt-4 mt-xl-0">
                      <i className="bx bx-receipt"></i>
                      <h4>Corporis voluptates sit</h4>
                      <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
                    </div>
                  </div>
                  <div className="col-xl-4 d-flex align-items-stretch">
                    <div className="icon-box mt-4 mt-xl-0">
                      <i className="bx bx-cube-alt"></i>
                      <h4>Ullamco laboris ladore pan</h4>
                      <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt</p>
                    </div>
                  </div>
                  <div className="col-xl-4 d-flex align-items-stretch">
                    <div className="icon-box mt-4 mt-xl-0">
                      <i className="bx bx-images"></i>
                      <h4>Labore consequatur</h4>
                      <p>Aut suscipit aut cum nemo deleniti aut omnis. Doloribus ut maiores omnis facere</p>
                    </div>
                  </div>
                </div>
    </div>*/}
            </div> 
          </div>

        </div>
      </section>
{/*
      <section id="features" className="features">
        <div className="container" data-aos="fade-up">

          <div className="row" data-aos="zoom-in" data-aos-delay="100">
            <div className="col-lg-3 col-md-4">
              <div className="icon-box">
                <i className="ri-store-line" style={{ color: "#ffbb2c;" }}></i>
                <h3><a href="">Lorem Ipsum</a></h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4 mt-md-0">
              <div className="icon-box">
                <i className="ri-bar-chart-box-line" style={{ color: "#5578ff;" }}></i>
                <h3><a href="">Dolor Sitema</a></h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4 mt-md-0">
              <div className="icon-box">
                <i className="ri-calendar-todo-line" style={{ color: "#e80368;" }}></i>
                <h3><a href="">Sed perspiciatis</a></h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4 mt-lg-0">
              <div className="icon-box">
                <i className="ri-paint-brush-line" style={{ color: "#e361ff;" }}></i>
                <h3><a href="">Magni Dolores</a></h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box">
                <i className="ri-database-2-line" style={{ color: "#47aeff;" }}></i>
                <h3><a href="">Nemo Enim</a></h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box">
                <i className="ri-gradienter-line" style={{ color: "#ffa76e;" }}></i>
                <h3><a href="">Eiusmod Tempor</a></h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box">
                <i className="ri-file-list-3-line" style={{ color: "#11dbcf;" }}></i>
                <h3><a href="">Midela Teren</a></h3>
              </div>
            </div>

          </div>

        </div>
      </section> */}


      {/* <section id="courses" className="courses">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Speakers</h2>
          <p>Speakers</p>
        </div>

        <div className="row" data-aos="zoom-in" data-aos-delay="100">

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="course-item">
              <img src="assets/img/course-1.jpg" className="img-fluid" alt="..."/>
              <div className="course-content">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h4>Web Development</h4>
                  <p className="price">$169</p>
                </div>

                <h3><a href="course-details.html">Website Design</a></h3>
                <p>Et architecto provident deleniti facere repellat nobis iste. Id facere quia quae dolores dolorem tempore.</p>
                <div className="trainer d-flex justify-content-between align-items-center">
                  <div className="trainer-profile d-flex align-items-center">
                    <img src="assets/img/trainers/trainer-1.jpg" className="img-fluid" alt=""/>
                    <span>Antonio</span>
                  </div>
                  <div className="trainer-rank d-flex align-items-center">
                    <i className="bx bx-user"></i>&nbsp;50
                    &nbsp;&nbsp;
                    <i className="bx bx-heart"></i>&nbsp;65
                  </div>
                </div>
              </div>
            </div>
          </div> 

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
            <div className="course-item">
              <img src="assets/img/course-2.jpg" className="img-fluid" alt="..."/>
              <div className="course-content">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h4>Marketing</h4>
                  <p className="price">$250</p>
                </div>

                <h3><a href="course-details.html">Search Engine Optimization</a></h3>
                <p>Et architecto provident deleniti facere repellat nobis iste. Id facere quia quae dolores dolorem tempore.</p>
                <div className="trainer d-flex justify-content-between align-items-center">
                  <div className="trainer-profile d-flex align-items-center">
                    <img src="assets/img/trainers/trainer-2.jpg" className="img-fluid" alt=""/>
                    <span>Lana</span>
                  </div>
                  <div className="trainer-rank d-flex align-items-center">
                    <i className="bx bx-user"></i>&nbsp;35
                    &nbsp;&nbsp;
                    <i className="bx bx-heart"></i>&nbsp;42
                  </div>
                </div>
              </div>
            </div>
          </div> 

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
            <div className="course-item">
              <img src="assets/img/course-3.jpg" className="img-fluid" alt="..."/>
              <div className="course-content">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h4>Content</h4>
                  <p className="price">$180</p>
                </div>

                <h3><a href="course-details.html">Copywriting</a></h3>
                <p>Et architecto provident deleniti facere repellat nobis iste. Id facere quia quae dolores dolorem tempore.</p>
                <div className="trainer d-flex justify-content-between align-items-center">
                  <div className="trainer-profile d-flex align-items-center">
                    <img src="assets/img/trainers/trainer-3.jpg" className="img-fluid" alt=""/>
                    <span>Brandon</span>
                  </div>
                  <div className="trainer-rank d-flex align-items-center">
                    <i className="bx bx-user"></i>&nbsp;20
                    &nbsp;&nbsp;
                    <i className="bx bx-heart"></i>&nbsp;85
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section> */}


      <section id="speakers" className="trainers">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Speakers</h2>
            <p>Launch Event Speakers</p>
          </div>
          <div className="row" data-aos="zoom-in" data-aos-delay="100">
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member">
                <img src="assets/img/speakers/GauravSen.png" className="img-fluid" alt="" />
                <div className="member-content">
                  <h4>Gaurav Sen</h4>
                  <span>Career Development</span>
                  <p>
                  Gaurav Sen is the founder of InterviewReady, which helps software engineers practice and prepare for interviews. Gaurav was chosen as the LinkedIn Top Voice in 2020. His YouTube channel on System Design and Algorithms has more than 4.2L subscribers. Gaurav is now part of an accelerator program hosted by Cornell University and funded by the state government of Maharashtra                  </p>
                  <div className="social">
                    <a href="https://linkedin.com/in/gkcs/"><i className="bi bi-linkedin"></i></a>
                    <a href="https://twitter.com/gkcs_"><i className="bi bi-twitter"></i></a>
                    {/* <a href=""><i className="bi bi-facebook"></i></a> */}
                    {/* <a href=""><i className="bi bi-instagram"></i></a> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member">
                <img src="assets/img/speakers/Dravisha.png" className="img-fluid" alt="" />
                <div className="member-content">
                  <h4>Dravisha Katoch</h4>
                  <span>Personal Branding</span>
                  <p>
                  Dravisha is a young professional who loves to transform ideas into action for growth, marketing, product experience, community building, personal branding, and mental health initiatives.

She is currently spearheading growth for a media-tech startup, The Signal (thesignal.co). On the side, she is also heading Learn for Less (learnforless.org) to teach marginalised students on how to build their personal brand, get equal opportunities via 2-day unique workshops at $1 along with a bundle of free resources.                  </p>
                  <div className="social">
                    <a href="https://www.linkedin.com/in/dravisha/"><i className="bi bi-linkedin"></i></a>
                    <a href="https://twitter.com/dravishadotcom"><i className="bi bi-twitter"></i></a>
                    {/* <a href=""><i className="bi bi-facebook"></i></a>
                    <a href=""><i className="bi bi-instagram"></i></a> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member">
                <img src="assets/img/speakers/AkankshaBhasin.png" className="img-fluid" alt="" />
                <div className="member-content">
                  <h4>Akanksha Bhasin</h4>
                  <span>Technical Community Evangelist</span>
                  <p>
                  Akanksha Bhasin is a Technical Community Evangelist at Symbl.ai and works in the Devrel space. She is the Founder of Women in Voice India, a GHCI Scholar, and a Web and Voice developer. She has 4+ years of experience in building technical communities and has actively led Google's Developer Student Clubs. She has impacted more than 2000 developers by hosting sessions on different technologies across different parts of India. She has also mentored students in Google code-in, hackathons and loves talking about start-ups, tech and anything related to community building.
                  </p>
                  <div className="social">
                    <a href="https://www.linkedin.com/in/akankshabhasin/"><i className="bi bi-linkedin"></i></a>
                    <a href="https://twitter.com/Akii_20"><i className="bi bi-twitter"></i></a>
                    {/* <a href=""><i className="bi bi-facebook"></i></a>
                    <a href=""><i className="bi bi-instagram"></i></a> */}
                  </div>
                </div>
              </div>
            </div> 

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member">
                <img src="assets/img/speakers/Amalu.jpeg" className="img-fluid" alt="" />
                <div className="member-content">
                  <h4>Amalu Susan</h4>
                  <span>Product Management</span>
                  <p>
                  Amalu Susan Santhosh is a Masters student at Duke University majoring in Engineering Management. She has worked as a Product Manager in a Fintech after graduating as a Computer Science Engineer. Her involvement in Women in Tech communities has earned her various scholarships including Generation Google Scholarship and Google GHCI Scholarship.                  </p>
                  <div className="social">
                    <a href="https://www.linkedin.com/in/amalu-susan-santhosh/"><i className="bi bi-linkedin"></i></a>
                    <a href="https://twitter.com/susan_amalu"><i className="bi bi-twitter"></i></a>
                    {/* <a href=""><i className="bi bi-facebook"></i></a>
                    <a href=""><i className="bi bi-instagram"></i></a> */}
                  </div>
                </div>
              </div>
            </div> 
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member">
                <img src="assets/img/speakers/ShubhendraVikram.jpeg" className="img-fluid" alt="" />
                <div className="member-content">
                  <h4>Shubhendra Vikram</h4>
                  <span>Entrepreneurship</span>
                  <p>
                  Shubhendra is founder and CEO of Hapramp Studio which builds technology for the creator economy space globally. Started up in college, he raised $1M in investment at age 23 from Indian Billionaire Anand Mahindra among 2847 applicants.

On the side he runs Project Parwaaz Foundation, an NGO committed to free virtual education in J&#38K and Ladakh.                 </p>
                  <div className="social">
                    <a href="https://www.linkedin.com/in/shubhendravikram/"><i className="bi bi-linkedin"></i></a>
                    <a href="https://twitter.com/shubhendrav"><i className="bi bi-twitter"></i></a>
                    {/* <a href=""><i className="bi bi-facebook"></i></a>
                    <a href=""><i className="bi bi-instagram"></i></a> */}
                  </div>
                </div>
              </div>
            </div> 
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member">
                <img src="assets/img/speakers/KhushbooVerma.jpeg" className="img-fluid" alt="" />
                <div className="member-content">
                  <h4>Khushboo Verma</h4>
                  <span></span>
                  <p>
                  Khushboo is a Computer Science Graduate from Indira Gandhi Delhi Technical University for Women, class of 2021. Being a technology-enthusiast, she loves exploring new technologies and leveraging them to solve real-life problems. She is driven by the will to create an impact and encourage diversity and inclusion in communities.
                 </p>
                  <div className="social">
                    <a href="https://www.linkedin.com/in/verma-khushboo/"><i className="bi bi-linkedin"></i></a>
                    <a href="https://twitter.com/khushbooverma_"><i className="bi bi-twitter"></i></a>
                    {/* <a href=""><i className="bi bi-facebook"></i></a>
                    <a href=""><i className="bi bi-instagram"></i></a> */}
                  </div>
                </div>
              </div>
            </div> 

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member">
                <img src="assets/img/speakers/Harshil.png" className="img-fluid" alt="" />
                <div className="member-content">
                  <h4>Harshil Agarwal</h4>
                  <span>Developer Advocate</span>
                  <p>
                  Harshil is a developer advocate who experiments with tech and builds cool projects. He enjoys sharing his knowledge with the community and empowering people on their journey.
                  </p>
                  <div className="social">
                    <a href="https://www.linkedin.com/in/harshil1712"><i className="bi bi-linkedin"></i></a>
                    <a href="https://twitter.com/harshil1712"><i className="bi bi-twitter"></i></a>
                    {/* <a href=""><i className="bi bi-facebook"></i></a>
                    <a href=""><i className="bi bi-instagram"></i></a> */}
                  </div>
                </div>
              </div>
            </div> 
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member">
                <img src="assets/img/speakers/Jayant.png" className="img-fluid" alt="" />
                <div className="member-content">
                  <h4>Jayant Mundhra</h4>
                  <span></span>
                  <p>
                  A self-published author with a knack for reading, Jayant comes from a background in data analytics and consulting and is presently working with the founders at one of India's fastest-growing edtech startup Classplus                  </p>
                  <div className="social">
                    <a href="https://www.linkedin.com/in/jshilanjanm/"><i className="bi bi-linkedin"></i></a>
                    {/* <a href=""><i className="bi bi-facebook"></i></a>
                    <a href=""><i className="bi bi-instagram"></i></a> */}
                    <a href="https://twitter.com/jshilanjanm"><i className="bi bi-twitter"></i></a>
                  </div>
                </div>
              </div>
            </div> 
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member">
                <img src="assets/img/speakers/MadhavBahl.png" className="img-fluid" alt="" />
                <div className="member-content">
                  <h4>Madhav Bahl</h4>
                  <span>Personal Branding</span>
                  <p>
                  Madhav has contributed a lot to the community of programmers and currently working as a Software Engineer at Microsoft, has a vision in life. His goal is to make each and every individual of this world proficient in at least one programming language. For the fulfillment of his vision, Madhav keeps mentoring students free of cost, spoke at more than 50 events/workshops/meetups/webinars. He has completed more than 15 (completely FREE) initiatives, reaching over 25000 people in the student community.              </p>
                  <div className="social">
                    <a href="https://www.linkedin.com/in/madhavbahl/"><i className="bi bi-linkedin"></i></a>
                    <a href="https://twitter.com/MadhavBahlMD"><i className="bi bi-twitter"></i></a>
                    {/* <a href=""><i className="bi bi-facebook"></i></a>
                    <a href=""><i className="bi bi-instagram"></i></a> */}
                  </div>
                </div>
              </div>
            </div> 
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member">
                <img src="assets/img/speakers/AieshaJan.jpeg" className="img-fluid" alt="" />
                <div className="member-content">
                  <h4>Aiesha Jan</h4>
                  <span>Social Entrepreneurship</span>
                  <p>
                  Aiesha runs Project Parwaaz Foundation, an NGO committed to free virtual education in J&K and Ladakh. In the last 15 months, 600+ volunteers from 23 Indian states have joined this Initiative and have taught 15,000+ classes to children across Jammu, Kashmir, and Ladakh.                         </p>
                  <div className="social">
                    <a href="https://www.linkedin.com/in/aiesha-jan-7283081a8/"><i className="bi bi-linkedin"></i></a>
                    <a href="https://twitter.com/aj_aiesha"><i className="bi bi-twitter"></i></a>
                    {/* <a href=""><i className="bi bi-facebook"></i></a>
                    <a href=""><i className="bi bi-instagram"></i></a> */}
                  </div>
                </div>
              </div>
            </div> 

          </div>

        </div>
      </section>
      <section id="team" className="trainers">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Our Team</h2>
            <p>Core Team</p>
          </div>
          <div className="row" data-aos="zoom-in" data-aos-delay="100">
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member">
                <img src="assets/img/team/akshita.jpg" className="img-fluid" alt="" />
                <div className="member-content">
                  <h4>Akshita Agrawalla</h4>
                  <span>Co-Founder</span>
                  <p>
                    Akshita currently works at Microsoft IDC, Hyderabad, as a Software Engineer. She is enthusiastic about Deep Learning and building communities. Apart from work, she like Dramatics, Art, and cats.
                  </p>
                  <div className="social">
                    <a href="https://twitter.com/akshi__11"><i className="bi bi-twitter"></i></a>
                    <a href="https://github.com/akshitaagarwa11a"><i className="bi bi-github"></i></a>
                    <a href="https://in.linkedin.com/in/akshitaagarwal11"><i className="bi bi-linkedin"></i></a>
                    <a href="https://instagram.com/akshita___agarwal"><i className="bi bi-instagram"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member">
                <img src="assets/img/team/haritha.png" className="img-fluid" alt="" />
                <div className="member-content">
                  <h4>Haritha Nair</h4>
                  <span>Co-Founder</span>
                  <p>
                    Haritha is currently working as a Software Engineer at Microsoft IDC, Hyderabad, parallelly pursuing a Masters in English Literature from Indira Gandhi National Open University. She spends most of her time developing tech products, reading and finding mundane muses to romanticize, or contributing to multiple social initiatives.
                  </p>
                  <div className="social">
                    <a href="https://twitter.com/margharithaa"><i className="bi bi-twitter"></i></a>
                    <a href="https://github.com/haritha1313"><i className="bi bi-github"></i></a>
                    <a href="https://www.linkedin.com/in/harithanair/"><i className="bi bi-linkedin"></i></a>
                    <a href="https://www.harithanair.com"><i className="bi bi-globe"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member">
                <img src="assets/img/team/sashrika.jpg" className="img-fluid" alt="" />
                <div className="member-content">
                  <h4>Sashrika Kaur</h4>
                  <span>Co-Founder</span>
                  <p>
                  Sashrika is currently working as a Software Engineer at Microsoft IDC, Hyderabad. She spends her weekends working part-time as a Hackathon Product Specialist for Major League Hacking and helping the community as a Coach. She is  an advocate for the women in the tech community and is a regular speaker at various events.
                  </p>
                  <div className="social">
                    <a href="https://twitter.com/SashrikaKaur"><i className="bi bi-twitter"></i></a>
                    <a href="https://github.com/sashrikakaur"><i className="bi bi-github"></i></a>
                    <a href="https://in.linkedin.com/in/sashrika-kaur"><i className="bi bi-linkedin"></i></a>
                    <a href="https://www.polywork.com/sashrikakaur"><i className="bi bi-globe"></i></a>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section>
    </main>

  )
}

export default Main
